import gsap from 'gsap';

export default class Hero {
    $el: HTMLElement;
    $$caseCards: NodeListOf<HTMLElement>;
    $$caseNavItems: NodeListOf<HTMLElement>;
    $caseButton: HTMLAnchorElement;
    $caseCardContainer;
    $caseCards: HTMLElement;
    $caseTriggerNext: HTMLElement;
    $caseTriggerPrev: HTMLElement;
    $caseTriggers: HTMLElement;
    $caseVisuals: HTMLElement;
    $cursor: HTMLElement;
    currentCaseIndex: number;
    numCases: number;

    constructor($el: HTMLElement) {
        this.$el = $el;

        this.$$caseCards = this.$el.querySelectorAll('.js-heroCaseCard');
        this.$$caseNavItems = this.$el.querySelectorAll('.js-heroCasesNavItem');
        this.$caseButton = this.$el.querySelector('.js-heroCaseButton')!;
        this.$caseCards = this.$el.querySelector('.js-heroCaseCards')!;
        this.$caseTriggerNext = this.$el.querySelector('.js-heroCaseTriggerNext')!;
        this.$caseTriggerPrev = this.$el.querySelector('.js-heroCaseTriggerPrev')!;
        this.$caseTriggers = this.$el.querySelector('.js-heroCaseTriggers')!;
        this.$caseVisuals = this.$el.querySelector('.js-heroCaseVisuals')!;
        this.$cursor = this.$el.querySelector('.js-heroCursor')!;
        this.currentCaseIndex = 0;
        this.isInteracted = false;
        this.numCases = this.$$caseCards.length;

        this.initListeners();
    }

    getElementByIndexAttr($$elements: NodeListOf<HTMLElement>, index: number): HTMLElement | undefined {
        const $return = Array.from($$elements).find(($el) => {
            const i: number | null = Number($el.dataset.index ?? null);

            if (i !== null) {
                return i === index;
            }

            return false;
        });

        return $return;
    }

    initListeners(): void {
        // Set cursor position
        this.$caseTriggers.addEventListener('mousemove', this.onTriggersMouseMove.bind(this), false);

        // Set cursor visibility
        this.$caseTriggers.addEventListener(
            'mouseout',
            () => {
                this.$cursor.classList.toggle('is-visible', false);
            },
            false
        );

        this.$caseTriggers.addEventListener(
            'mouseover',
            () => {
                this.$cursor.classList.toggle('is-visible', true);
            },
            false
        );

        // Set cursor direction
        this.$caseTriggerNext.addEventListener(
            'mouseover',
            () => {
                this.$cursor.classList.add('is-next');
                this.$cursor.classList.remove('is-prev');
            },
            false
        );

        this.$caseTriggerPrev.addEventListener(
            'mouseover',
            () => {
                this.$cursor.classList.add('is-prev');
                this.$cursor.classList.remove('is-next');
            },
            false
        );

        // Nav
        this.$caseTriggerNext.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                let index = this.currentCaseIndex + 1;
                index = index > this.numCases - 1 ? 0 : index;
                this.isInteracted = true;
                this.setCaseIndex(index);
            },
            false
        );

        this.$caseTriggerPrev.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                let index = this.currentCaseIndex - 1;
                index = index < 0 ? this.numCases - 1 : index;
                this.isInteracted = true;
                this.setCaseIndex(index);
            },
            false
        );

        this.$$caseNavItems.forEach(($caseNavItem) => {
            $caseNavItem.addEventListener(
                'animationend',
                () => {
                    if (!this.isInteracted) {
                        let index = this.currentCaseIndex + 1;
                        index = index > this.numCases - 1 ? 0 : index;
                        this.setCaseIndex(index);
                    }
                },
                false
            );
            $caseNavItem.addEventListener('pointerup', this.onCaseNavItemClick.bind(this), false);
        });
    }

    onCaseNavItemClick(e: PointerEvent): void {
        e.preventDefault();

        if (e.currentTarget instanceof HTMLElement) {
            const index: number | null = Number(e.currentTarget.dataset.index ?? null);

            if (index !== null) {
                this.isInteracted = true;
                this.setCaseIndex(index);
            }
        }
    }

    onTriggersMouseMove(e: MouseEvent): void {
        if (e.currentTarget instanceof HTMLElement) {
            gsap.to(this.$cursor, {
                duration: 0.25,
                ease: 'easeOut',
                '--x': e.layerX + 'px',
                '--y': e.layerY + 'px',
            });
        }
    }

    setCaseIndex(index: number): void {
        if (index === this.currentCaseIndex) return;

        const $nextCard = this.getElementByIndexAttr(this.$$caseCards, index);
        const $nextNavItem = this.getElementByIndexAttr(this.$$caseNavItems, index);
        const $nextVisual = this.$caseVisuals.querySelector(`[data-index="${index}"]`);
        const direction = index > this.currentCaseIndex ? 1 : -1;

        // Deactivate all nav items
        this.$$caseNavItems.forEach(($caseNavItem) => $caseNavItem.classList.remove('is-active'));

        // Active next nav item
        if ($nextNavItem) {
            $nextNavItem.classList.add('is-active');
        }

        // Animate visuals
        if ($nextVisual) {
            this.$caseVisuals.append($nextVisual);
            this.$caseVisuals.style.setProperty('--direction', String(direction));

            gsap.fromTo(
                this.$caseVisuals,
                {
                    '--progress-in': direction === 1 ? '1' : '-1',
                    '--progress-out': 0,
                },
                {
                    '--progress-in': '0',
                    '--progress-out': direction === 1 ? '1' : '-1',
                    duration: 0.75,
                    ease: 'power2.inOut',
                }
            );
        }

        if ($nextCard) {
            // Animate card
            this.$caseCards.append($nextCard);

            // Update button
            const caseUrl = $nextCard.dataset.url ?? null;

            if (caseUrl) {
                this.$caseButton.href = caseUrl;
            }
        }

        this.currentCaseIndex = index;
    }

    /**
     * Getters & setters
     */

    get isInteracted(): boolean {
        return this.$el.classList.contains('is-interacted');
    }

    set isInteracted(isInteracted: boolean) {
        this.$el.classList.toggle('is-interacted', isInteracted);
    }
}
