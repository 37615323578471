export default class LineWrap {
    $el: HTMLElement;
    text: string | undefined;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.text = this.$el.textContent?.trim();
        this.$el.textContent = '';

        this.initListeners();
        this.update();
    }

    initListeners(): void {
        document.fonts.ready.then(this.update.bind(this));

        window.addEventListener('resize', this.update.bind(this), false);

        // const resizeObserver = new ResizeObserver(this.update.bind(this));
        // resizeObserver.observe(this.$el);
    }

    update(): void {
        const words = this.text?.split(/\s+/);
        let lineIndex = 0;
        let lines: string[][] = [];
        let currentOffsetTop = 0;

        this.$el.innerHTML = '';
        this.$el.style.display = 'block';

        words?.forEach((word, index) => {
            const $word = document.createElement('span');

            if (index < words.length - 1) {
                $word.textContent = word + ' ';
            } else {
                $word.textContent = word;
            }

            this.$el.append($word);
        });

        // Get Y coords on next paint
        window.requestAnimationFrame(() => {
            Array.from(this.$el.children).forEach(($word: Element) => {
                if ($word instanceof HTMLElement) {
                    const offsetTop = $word.offsetTop;

                    if (offsetTop > currentOffsetTop) {
                        lineIndex++;
                        currentOffsetTop = offsetTop;
                    }

                    lines[lineIndex] ??= [];
                    lines[lineIndex].push($word.textContent ?? '');
                }
            });

            // Clear dummy words
            this.$el.innerHTML = '';
            lines = lines.filter((line) => line);

            lines.forEach((line) => {
                const $lineInner = document.createElement('div');
                const $line = document.createElement('div');

                $lineInner.classList.add('line__inner');
                $lineInner.textContent = line.join(' ').trim();

                $line.append($lineInner);
                $line.classList.add('line');

                this.$el.append($line);
            });

            this.$el.style.display = '';
        });
    }
}
