import '@css/app.scss';

import App from './App';
import CookieConsent from './components/CookieConsent';
import { Equinox, equinoxLoadListener } from './components/Equinox';
import Hero from './components/Hero';
import LazyVideo from './components/LazyVideo';
import LineWrap from './components/LineWrap';
import Nav from './components/Nav';
import TextSplit from './components/TextSplit';
import Ticker from './components/Ticker';
import Video from './components/Video';

const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

window.__APP__ = new App([
    { component: CookieConsent, name: 'cookieConsent' },
    { component: Equinox, name: 'equinox' },
    { component: Hero, name: 'hero' },
    { component: LazyVideo, name: 'lazyVideo' },
    { component: LineWrap, name: 'lineWrap' },
    { component: Nav, name: 'nav' },
    { component: TextSplit, name: 'textSplit' },
    { component: Ticker, name: 'ticker' },
    { component: Video, name: 'video' },
]);

equinoxLoadListener();

// Vite HMR
// @ts-ignore
if (import.meta.hot) {
    // @ts-ignore
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}
